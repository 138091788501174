<div
  class="header-wrapper"
  #wrapperHeader
  [class.transparent-header]="showTransparentHeader"
>
  <div class="header-inner">
    <div class="logo-area logo-area__wrapper">
      <a
        class="logo-area__logo"
        (click)="logoClicked.emit($event)"
        href="{{ originUrl }}"
        data-testid="main-logo"
      >
        <now-plus-logo></now-plus-logo>
      </a>
    </div>
    <div class="menu-area" *ngIf="showMenu">
      <ul
        class="header-menu"
        id="headerMenuLoggedIn"
        *ngIf="isLoggedIn"
        data-testid="header-logged-in"
      >
        <li class="header-menu-item header-menu-item__bell">
          <now-braze-notification-bell
            class="bell"
            [showTitle]="false"
            [openOnHover]="false"
          ></now-braze-notification-bell>
        </li>
        <li class="header-menu-item header-menu-item__account">
          <a class="nav-link" [routerLink]="'/account'">
            <span>{{ 'menu.myAccount' | translate }}</span>
          </a>
        </li>
        <li class="header-menu-item header-menu-item__homepage">
          <a class="nav-link" href="{{ originUrl }}">
            <span>{{ 'menu.homepage' | translate }}</span>
          </a>
        </li>
      </ul>

      <ul
        class="header-menu"
        id="headerMenuLoggedOut"
        *ngIf="!isLoggedIn"
        data-testid="header-logged-out"
      >
        <li class="header-menu-item">
          <a class="nav-link" (click)="toLoginClicked.emit($event)">
            <span>{{ 'menu.login' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
