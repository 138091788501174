import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf } from '@angular/common';
import { Router } from '@angular/router';
import { ConsentService } from '@nowffc-shared/services/tracking/consent.service';
import { SubscriberDataSignalsState } from '../../signals/states/subscriber-data.signals-state';
import * as fromStore from '@nowffc-state/store';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthService } from '@nowffc-auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'now-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [TranslateModule, NgForOf],
})
export class FooterComponent implements OnInit, OnDestroy {
  currentYear = new Date().getFullYear();

  userHasPurSubscription = false;

  entries: {
    title: string;
    links: {
      name: string;
      href: string;
      onClickHandler?: any;
      checkPur: boolean;
    }[];
  }[] = [
    {
      title: 'Services',
      links: [
        {
          name: 'Hilfe & Kontakt',
          href: 'https://community.plus.rtl.de/s',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Impressum',
          href: 'https://www.tvnow.de/impressum',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Privacy Center',
          href: '#',
          onClickHandler: (e: any) => {
            e.preventDefault();
            this.consentService.showPrivacyCenter();
          },
          checkPur: true,
        },
        {
          name: 'Datenschutz',
          href: 'https://www.tvnow.de/datenschutz',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Nutzungsbedingungen',
          href: 'https://www.tvnow.de/agb',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Verträge hier kündigen',
          href: 'https://my.tvnow.de/mein-paket/vertraege-kuendigen',
          onClickHandler: (e: any) => {
            e.preventDefault();
            this.router
              .navigate(['/mein-paket/vertraege-kuendigen'])
              .then(() => {});
          },
          checkPur: false,
        },
      ],
    },
    {
      title: 'Wir sind RTL±',
      links: [
        {
          name: 'Über RTL±',
          href: 'https://company.rtl.com/de//?utm_source=rtl&amp;utm_medium=owned&amp;utm_campaign=footer/',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Jobs',
          href: 'https://www.mediengruppe-rtl.de/jobs/',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Presse',
          href: 'https://kommunikation.mediengruppe-rtl.de/news/allemarken/uebersicht/',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Werben auf RTL±',
          href: 'https://www.ad-alliance.de/cms/portfolio/digital/portfolio.html#/digital/portfolio/rtlplus/profil/',
          onClickHandler: () => {},
          checkPur: false,
        },
        {
          name: 'Newsletter',
          href: 'https://my.plus.rtl.de/newsletter',
          onClickHandler: (e: MouseEvent) => {
            e.preventDefault();
            this.router.navigate(['/newsletter']);
          },
          checkPur: false,
        },
      ],
    },
  ];

  private readonly subs = new Subscription();

  constructor(
    private readonly consentService: ConsentService,
    private readonly subscriberDataSignalsState: SubscriberDataSignalsState,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.store
        .select(fromStore.auth.selectAuthenticationInitialized)
        .pipe(
          filter((initialized) => initialized),
          take(1),
          switchMap(() =>
            this.authService.isAuthenticated().pipe(
              take(1),
              tap(async (isLoggedIn) => {
                if (isLoggedIn) {
                  const subscriberData =
                    await this.subscriberDataSignalsState.getSubscriberData();
                  this.userHasPurSubscription =
                    subscriberData().purSubscription !== undefined;
                }
              }),
            ),
          ),
        )
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
