import { Injectable, OnDestroy, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoyjoyService } from '@plus/ngx-loyjoy';
import { AuthService } from '@nowffc-auth/auth.service';
import { SubscriberData } from '@nowffc-shared/interfaces/subscriber-data/subscriber-data';
import { User } from '@nowffc-shared/interfaces/user';
import { ConsentService } from '@nowffc-shared/services/tracking/consent.service';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { PaymentBearerTypeKey } from '@nowffc-shared/types/payment-bearer-type-key';
import * as fromStore from '@nowffc-state/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { SubscriberDataSignalsState } from '../../../signals/states/subscriber-data.signals-state';
import { ActivationEnd, Router } from '@angular/router';
import { CUSTOMER_CARE_FEATURE_TOGGLES } from '@nowffc-shared/services/feature-toggle/unleash.types';
import { UnleashService } from '@nowffc-shared/services/feature-toggle/unleash.service';
import { environment } from '@nowffc-environment/environment';

@Injectable({ providedIn: 'root' })
export class AccountChatBotService implements OnDestroy {
  private loyJoyExperienceId?: string;

  private readonly onDestroySubject = new Subject<void>();

  subscriberData: Signal<SubscriberData>;
  private user: User | undefined;

  readonly maxMobileWidth = 768;
  private chatBotClosedAutomatically = false;
  private hideChatBot = new BehaviorSubject<boolean>(false);

  chatBotVisibilityCriteria$ = combineLatest([
    this.authService.isAuthenticated(),
    this.consentService.getConsentedAll$().pipe(shareReplay(1)),
    this.hideChatBot,
  ]);

  constructor(
    private readonly loyJoyService: LoyjoyService,
    private readonly store: Store,
    private readonly consentService: ConsentService,
    private readonly windowRef: WindowRef,
    private readonly subscriberDataSignalsState: SubscriberDataSignalsState,
    private readonly authService: AuthService,
    private readonly unleashService: UnleashService,
    private readonly router: Router,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        map((event) =>
          (event as ActivationEnd).snapshot.pathFromRoot.some(
            (snapshot) => snapshot.routeConfig?.data?.disableChatBot,
          ),
        ),
      )
      .subscribe(this.hideChatBot);
  }
  private chatbotInitialized = false;
  private chatbotIsOpen = false;

  async init() {
    await this.initUserStateSub();

    return new Promise<void>((resolve, reject) => {
      this.chatBotVisibilityCriteria$.subscribe({
        next: async ([isLoggedIn, consentedAll, shouldHide]) => {
          if (!isLoggedIn) {
            this.loyJoyService.remove();
            resolve();
            return;
          }

          await this.initExperienceId(consentedAll);

          this.subscriberData =
            await this.subscriberDataSignalsState.getSubscriberData();

          const hasBigEnoughWindow =
            this.windowRef.nativeWindow.innerWidth >= 1000;

          if (!this.chatbotInitialized && this.loyJoyExperienceId) {
            await this.loyJoyService.init();
            this.chatbotInitialized = true;
            this.loyJoyService.boot({
              process: this.loyJoyExperienceId,
              callbacks: this.chatBotVarCallbacks,
              cookie: consentedAll,
              open: !shouldHide && hasBigEnoughWindow,
              eventListeners: [
                (name) => {
                  switch (name) {
                    case 'open':
                      this.chatbotIsOpen = true;
                      break;
                    case 'close':
                      this.chatbotIsOpen = false;
                      break;
                  }
                },
              ],
            });
            if (shouldHide) {
              this.chatBotClosedAutomatically = true;
            }
          } else if (shouldHide && this.chatbotIsOpen) {
            this.loyJoyService.close();
            this.chatBotClosedAutomatically = true;
          } else if (
            !shouldHide &&
            !this.chatbotIsOpen &&
            this.chatBotClosedAutomatically
          ) {
            this.loyJoyService.open();
            this.chatBotClosedAutomatically = false;
          }
          resolve();
        },
        error: reject,
      });
    });
  }

  private async initExperienceId(consentedAll: boolean) {
    /**
     * TODO: We are currently working on a combined experience irregardless of whether consent has been given or not.
     * Once this has been implemented, we can remove this check as well as the environment entries.
     * */
    if (!consentedAll) {
      this.loyJoyExperienceId =
        environment.tracking.chatbotNoConsentExperienceId;
      return;
    }

    const chatboxExperienceVariant = await firstValueFrom(
      this.unleashService.getVariant(
        CUSTOMER_CARE_FEATURE_TOGGLES.CHATBOT_EXPERIENCE_WITH_CONSENT,
      ),
    );

    if (chatboxExperienceVariant.enabled) {
      this.loyJoyExperienceId = chatboxExperienceVariant.payload?.value;
    }
  }

  private async initUserStateSub() {
    this.user = await firstValueFrom(
      this.store.select(fromStore.auth.selectUserEntity),
    );
  }

  private readonly chatBotVarCallbacks = {
    firstName: () => {
      return this.user ? this.user.firstname : '';
    },
    lastName: () => {
      return this.user ? this.user.lastname : '';
    },
    email: () => {
      return this.user ? this.user.email : '';
    },
    customerId: () => {
      return this.user ? this.user.uid : '';
    },
    paymentBearerTypeKey: () => {
      return this.resolvePaymentBearerTypeKey();
    },
    productName: () => {
      return this.subscriberData().internalSubscription.currentPhase
        .productName;
    },
    hasFreeSubscription: () => {
      return !this.subscriberData().computed.inPay;
    },
  };

  ngOnDestroy(): void {
    this.onDestroySubject.next();
  }

  isMobile() {
    return this.windowRef.nativeWindow.innerWidth < this.maxMobileWidth;
  }

  private resolvePaymentBearerTypeKey(): PaymentBearerTypeKey | null {
    if (
      this.subscriberData().internalSubscription &&
      this.subscriberData().internalSubscription.status === 'PAY'
    ) {
      switch (this.subscriberData().internalSubscription.paymentBearerType) {
        case 'AMAZON_PAY':
          return 'payment.bearer.type.amazonpay';
        case 'BANK_ACCOUNT':
          return 'payment.bearer.type.debit';
        case 'CREDIT_CARD':
          return 'payment.bearer.type.creditcard';
        case 'PAYPAL':
          return 'payment.bearer.type.paypal';
      }
    } else if (
      this.subscriberData().dtagSubscription ||
      this.subscriberData().externalPartnerDetails.length > 0
    ) {
      return 'payment.bearer.type.partner';
    } else if (this.subscriberData().iapSubscriptions.length > 0) {
      switch (this.subscriberData().iapSubscriptions[0].type) {
        case 'APPLE':
          return 'payment.bearer.type.appleiap';
        case 'AMAZON':
          return 'payment.bearer.type.amazoniap';
      }
    }

    return null;
  }
}
