<footer class="rtlplus-footer">
  <div class="rtlplus-container">
    <div class="top">
      <div class="categories-container">
        <div class="categories" *ngFor="let entry of entries">
          <h4>{{ entry.title }}</h4>

          @for (link of entry.links; track $index) {
            @if (link.checkPur === false || !userHasPurSubscription) {
              <a
                class="categorie-entry"
                [href]="link.href"
                (click)="link.onClickHandler($event)"
                target="_blank"
                rel="noopener noreferrer"
              >{{ link.name }}</a
              >
            }
          }
        </div>
      </div>
      <div class="copyright">© {{ currentYear }} RTL interactive GmbH</div>
    </div>
    <div class="bottom">
      <div class="copyright">© {{ currentYear }} RTL interactive GmbH</div>
    </div>
  </div>
</footer>
