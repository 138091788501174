import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { environment } from './environments/environment';
import { defineCustomElements } from '@plus/ds/dist/loader';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { ErrorsModule } from '@nowffc-errors/errors.module';
import { RootStoreModule } from '@nowffc-state/root-store.module';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './app/modules/core/http-token.interceptor';
import { HttpErrorInterceptor } from './app/modules/core/http-error.interceptor';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';
import localeDe from '@angular/common/locales/de';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ToastrModule } from 'ngx-toastr';
import { NowToastComponent } from '@nowffc-shared/components/toastr/now-toast.component';
import { NowOverlayModule } from '@nowffc-overlay/now-overlay.module';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { indexedDbConfig } from './indexed-db-config';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LazyTranslateLoader } from './app/modules/core/lazy-translate-loader';
import { AuthStoreModule } from '@nowffc-state/auth/auth-store.module';
import { provideUnleashExperimentLogger } from '@nowffc-shared/services/feature-toggle/unleash-experiment-processor.service';
import { loyjoyTrackingProvider } from '@plus/ngx-loyjoy';
import { TrackingService } from '@nowffc-shared/services/tracking/tracking.service';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe, 'de-DE');

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      ErrorsModule,
      RootStoreModule,
      AuthStoreModule,
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useClass: LazyTranslateLoader,
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler,
        },
      }),
      ToastrModule.forRoot({
        toastComponent: NowToastComponent,
        timeOut: 10000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        enableHtml: true,
      }),
      NowOverlayModule.forRoot({
        autoClose: true,
      }),
      NgxIndexedDBModule.forRoot(indexedDbConfig),
    ),
    provideAnimations(),
    provideRouter(
      APP_ROUTES,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    provideUnleashExperimentLogger(),
    loyjoyTrackingProvider(TrackingService),
  ],
}).catch(console.error);

defineCustomElements().catch(console.error);
